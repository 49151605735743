'use client';

import { FC, PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';
import { AlertPayload } from './types';
import { ShellAlertDialog } from './shell-alert-dialog';
type OpenAlertDialogHandler<TValue = true> = (payload: AlertPayload<TValue>) => Promise<TValue>;
const AlertDialogContext = createContext<OpenAlertDialogHandler<any>>({} as OpenAlertDialogHandler<any>);
type AlertToken<TValue = true> = {
  payload: AlertPayload<TValue>;
  promise: Promise<TValue | null>;
  resolve: (value: TValue | null) => void;
};
function createAlertToken<TValue = true>(payload: AlertPayload<TValue>) {
  let done: AlertToken<TValue>['resolve'];
  const promise = new Promise<TValue | null>(resolve => {
    done = resolve;
  });
  return {
    payload,
    promise,
    resolve: done!
  };
}
export const AlertDialogProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const [currentAlert, setCurrentAlert] = useState<AlertToken<any> | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const openAlertDialog = useCallback(function <TValue = true>(payload: AlertPayload<TValue>) {
    const token = createAlertToken<TValue>(payload);
    setCurrentAlert(token);
    setIsOpen(true);
    return token.promise;
  }, []);
  const close = useCallback((value: any) => {
    currentAlert?.resolve(value);
    setIsOpen(false);
  }, [currentAlert]);
  return <AlertDialogContext.Provider value={openAlertDialog} data-sentry-element="unknown" data-sentry-component="AlertDialogProvider" data-sentry-source-file="alert-dialog-provider.tsx">
      {children}
      {currentAlert && <ShellAlertDialog {...currentAlert.payload} open={isOpen} onClose={close} />}
    </AlertDialogContext.Provider>;
};
export function useAlertDialog<TValue = true>() {
  return useContext(AlertDialogContext) as OpenAlertDialogHandler<TValue>;
}